/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~ionic4-auto-complete/auto-complete";

@import '~swiper/scss';
@import '~swiper/scss/pagination';
@import '~@ionic/angular/css/ionic-swiper';

.md,
.ios {

  .menu-logo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--dark_bg_color);
    z-index: 2;

    img {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      padding-bottom: 8px;
      padding-top: 5px;
    }
  }

  .menu-close {
    position: fixed;
    right: 10px;
    top: calc(var(--ion-safe-area-top) + 10px);
    width: 22px;
    height: 22px;
    z-index: 2;
    cursor: pointer;
    display: none;

    &.native {
      top: 38px;
    }

    @media (max-width: 375px) {
      display: block;
    }
  }

  .alert-message {
    span {
      text-align: left;
      display: block;
    }
  }

  ion-auto-complete {
    width: 100%;

    ion-searchbar {
      padding: 0 !important;
    }

    ul {
      width: 100%;
      left: 0;

      & > li {
        padding: 0;

        span {
          padding: 15px;
          display: block;
        }
      }
    }

    .loading input.searchbar-input {
      background-position: right 36px center;
    }
  }

  .searchbar-input {
    height: 58px;
    box-shadow: 0 0 0;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    background: #FFFFFF;
    color: #232323;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 65px;
  }

  .rtl {
    .searchbar-input {
      padding-right: 20px;
      padding-left: 65px;
    }

    .header-search-filters {
      right: 10px !important;
      left: auto;
    }
  }

  .success {
    .searchbar-input {
      border-color: #27AE60;
    }
  }

  .searchbar-search-icon {
    top: 18px;
    left: auto;
    right: 12px;
  }

  .rtl {
    .searchbar-search-icon {
      left: 16px !important;
      right: unset !important;
    }

    .loading {
      input.searchbar-input {
        background-position: left 42px center;
      }
    }
  }

  .searchbar-clear-button {
    display: none !important;
  }

  ion-title {
    color: #ffffff;
    font-size: 22px;

    @media (max-width: 370px) {
      font-size: 19px;
      padding-top: 0 !important;
      padding-left: 10px;
      padding-right: 10px;
    }

    .small {
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;

      a {
        color: #ffffff;
      }
    }
  }

  .buttons-last-slot {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;

      & > li {
        float: left;
        margin-left: 20px;

        @media (max-width: 760px) {
          margin-left: 10px;
          margin-right: 5px;
        }

        a {
          color: #ffffff;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 16px;

          @media (max-width: 760px) {
            font-size: 13px;
          }
        }
      }
    }
  }

  .avatar {
    height: 42px;
    width: 42px;
    background-color: #525974;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    border-radius: 50%;
    line-height: 42px;
    text-transform: uppercase;
  }

  ion-header {
    padding-top: 0;
    background-color: #F8F8F9;
    z-index: 999;

    &.dark {
      background: #10111C;

      .header-btn {
        --ion-color-dark: #ffffff;
        --ion-color-dark-shade: #ffffff;
        --ion-color-dark-tint: #ffffff;
      }

      ion-toolbar {
        ion-title {
          padding-inline-end: 55px;
          -webkit-padding-end: 55px;
        }
      }
    }

    &.white {
      background: #ffffff;
    }

    ion-toolbar {
      .buttons-first-slot {
        img {
          margin-left: 10px;
        }
      }
    }
  }

  ion-select {
    border: #bfbfc6 1px solid;
    border-radius: 3px;
    background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;

    &.form-controll {
      width: 50%;
    }
  }

  ion-alert {
    button[disabled] {
      .alert-radio-label {
        opacity: 0.3;
      }

      .alert-radio-icon {
        opacity: 0.3;
      }

    }
  }

  ion-input {
    border: #bfbfc6 1px solid;
    border-radius: 4px;
    background-color: #ffffff;

    --padding-top: 8px;
    --padding-bottom: 8px;
    --padding-start: 10px !important;
  }

  ion-textarea {
    border: #bfbfc6 1px solid;
    border-radius: 3px;
    background-color: #ffffff;
    --padding-start: 10px !important;
  }

  ion-menu {

    ion-item {
      --padding-end: 10px;
    }

    .item {
      color: #262626;
      font-size: 16px;

      &.space {
        margin-top: 10px;
      }

      &.half-space {
        margin-top: 5px;
      }

      &.safe-space {
        margin-top: 3px;
      }

      &.copyright {
        font-size: 10px;
        color: #E0E0E0;
        margin-top: 10px;

        &.head {
          font-size: 12px;
          margin-top: 20px;
        }
      }

      &.bottom-logo-space {
        margin-bottom: 65px;
      }

      &.title {
        font-weight: bold;
        margin-top: 15px;

        &.first {
          margin-top: 0;
        }
      }

      a {
        text-decoration: none;
        color: #3583ea;
      }

      ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 25px;

        & > li {
          margin-top: 5px;
        }
      }

      &.line {
        border-bottom: #ffffff 1px solid;
        padding-bottom: 10px;
      }

      &.space-bottom {
        padding-bottom: 20px;
      }
    }

    .list-ios {
      margin-top: 7px;
    }
  }

  .page-wrapper {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  ion-footer {
    ion-toolbar {
      max-width: 100%;

      ion-button {
        font-size: 16px;
        text-transform: none;
        width: 99%;
        max-width: 400px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      ion-title {
        padding-top: 0;
        padding-inline-start: 13px;
        -webkit-padding-start: 13px;
        padding-inline-end: 13px;
        -webkit-padding-end: 13px;
      }
    }
  }

}

.ios {
  ion-toolbar {
    ion-title {
      padding-inline-start: 55px;
      -webkit-padding-start: 55px;
      padding-inline-end: 15px;
      -webkit-padding-end: 15px;
      padding-top: 4px !important;

      &.without-back-btn {
        padding-inline-start: 15px;
        -webkit-padding-start: 15px;
      }

      &.with-cancel-btn {
        padding-inline-end: 85px;
        -webkit-padding-end: 85px;
      }
    }
  }
}

.item {
  .highlight {
    font-weight: 700;
  }
}

.alert-button {
  &.main {
    font-weight: bold;
  }

  &.secondary.sc-ion-alert-ios {
    &:last-child {
      font-weight: normal;
    }
  }
}

.header-avatar {
  overflow: hidden;
  width: 42px;
  height: 42px;
  border-radius: 50%;

  &_btn {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }
}

.swiper-pagination-bullet {
  border: #ffffff 1px solid;
  width: 12px;
  height: 12px;
}

.swiper .swiper-pagination-bullet-active {
  background: #666670;
}

.swiper-slide, .swiper-pagination {
  --swiper-pagination-bullet-inactive-opacity: 0.35;
}

.swiper-pagination {
  padding-left: 34px;
  padding-right: 34px;
}

.small-slider {
  .swiper-pagination {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
  }
}

.swiper-button-prev {
  height: 34px;
  width: 34px;
  left: 2px;
  bottom: 3px;
  background: url('assets/img/slider-arrow-prev.svg');
  background-size: contain;
  cursor: pointer;
  position: absolute;
  z-index: 11;
  opacity: 0.85;

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.swiper-button-next {
  height: 34px;
  width: 34px;
  bottom: 3px;
  background: url('assets/img/slider-arrow-next.svg');
  background-size: contain;
  cursor: pointer;
  position: absolute;
  right: 2px;
  z-index: 11;
  opacity: 0.85;

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.tip-guideInfo {
  .content {
    b {
      color: #1E5598;
    }

    ol {
      margin: 0 0 0 17px;
      padding: 0;

      li {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.guide-dot {
  &.active {
    background: #F4933A80 !important;
  }
}

.sub-toolbar {
  &-wrap {
    margin-top: 80px;
  }

  &-logo {
    margin-bottom: 20px;

    &_label {
      margin-bottom: 10px;
    }
  }

  &-profile {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}

.header-md::after {
  background: transparent;
}

.ios {
  ion-footer {
    ion-toolbar {
      ion-title {
        padding-top: 0 !important;
      }
    }
  }
}

.web-image-upload {
  .shot {
    backdrop-filter: blur(4px);
  }
}

.web-scan {
  .shot {
    backdrop-filter: blur(2px);

    &.shot-clean {
      backdrop-filter: none;
    }
  }
}

.custom-popover {
  position: relative;
  padding: 5px 0 5px 10px;
  background: #FFFFFF;
  height: 300px;

  &_item {
    position: relative;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
    color: var(--lj-blue, #0D66ED);

    &.np {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.with-icon {
      padding: 10px 15px 10px 38px;

      img {
        position: absolute;
        left: 10px;
        top: 10px;
      }
    }

    &.slim {
      padding-bottom: 5px;
    }

    &.title {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 24px;
      padding-bottom: 0;
      color: var(--mid-grayblue, #8C8D97);
    }

    &.first {
      padding-top: 5px;
    }

    &.active {
      font-weight: 700;
    }

    &.line {
      border-bottom: #DADADA 1px solid;
    }

    line-height: 24px;
  }
}

.show-tablet {
  display: none !important;

  &-block,
  &-flex {
    display: none !important;
  }

  @media (max-width: 1194px) {

    &-block {
      display: block !important;
    }

    &-flex {
      display: flex !important;
    }

    display: inline-block !important;
  }
}

@media (max-width: 1194px) {
  .hide-tablet {
    display: none !important;
  }
}

.popover-actions {
  background: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;

  ion-item {
    --min-height: 38px;
  }
}

